/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-19 19:13:05
 * @LastEditors: AllenXD
 * @LastEditTime: 2022-05-30 09:26:16
 */
import { mapGetters } from 'vuex'
import { wImgValid, wVerifyEmail } from '@/plugins/verifier'
import { wGetImgList } from '@/plugins/utils'
import HandleData from '@/service/Custom'
export default {
  name: 'CustomAddForm',
  data () {
    const self = this
    return {
      form: {
        name: '',
        product_name: '',
        audience_desc: '',
        first_trade_id: '',
        second_trade_id: '',
        contact_person_name: '',
        phone: '',
        email: '',
        user_list: [],
        businessLicense: []
      },
      options: [],
      tradeList: [],
      stradeList: [],
      rules: {
        name: [{
          validator (rule, val, res) {
            if (val === '') {
              res('请填写客户名称')
            }
            if (val.length > 50) {
              res('客户名称不超过50个字')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }],
        product_name: [{
          async validator (rule, val, res) {
            if (val === '') {
              res('请填写产品名称')
              return
            }
            if (val.length > 50) {
              res('产品名称不超过50个字')
              return
            }
            const payload = { product_name: val }
            if (!self.isAdd) {
              payload.id = self.form.custom_id
            }
            const data = await self.$wPost('/operate/custom/query_pname.do', payload)
            if (data === true) {
              res('该产品已存在')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }],
        first_trade_id: [{
          validator (rule, val, res) {
            if (self.form.first_trade_id === '' && self.form.second_trade_id === '') {
              res('请选择所属行业')
              return
            }
            if (self.form.second_trade_id === '') {
              res('请选择二级行业')
              return
            }
            res()
          },
          trigger: ['blur', 'change'],
          required: true
        }],
        contact_person_name: [{
          validator (rule, val, res) {
            if (val.length > 50) {
              res('联系人不超过50个字')
              return
            }
            res()
          },
          trigger: 'blur'
        }],
        phone: [{
          validator (rule, val, res) {
            if (val && !/^1\d{10}$/.test(val)) {
              res('请填写正确的手机号码')
            }
            res()
          },
          trigger: 'blur'
        }],
        audience_desc: [
          {
            max: 500,
            message: '目标人群描述不超过500个字符',
            trigger: ['blur']
          }
        ],
        email: [{
          validator (rule, val, res) {
            if (val !== '' && !wVerifyEmail(val)) {
              res('请填写正确的电子邮箱')
            }
            res()
          },
          trigger: 'blur'
        }]
      }
    }
  },
  methods: {
    async init () {
      const tradeData = await this.$wPost('/common/list_trade.do')
      this.tradeList = tradeData
      if (!this.isAdd) {
        const data = await this.$wPost('/operate/custom/get.do', { id: this.$route.params.id })
        console.log(data)
        await this.tradeChange(data.first_trade_id)
        for (const i in data) {
          this.$set(this.form, i, data[i])
        }
        this.$set(this.form, 'name', data.custom_name)
        if (this.form.custom_operate_license_vo) {
          const fileList = wGetImgList(this.form.custom_operate_license_vo.license_path, this.form.custom_operate_license_vo.file_name)
          this.$set(this.form, 'businessLicense', fileList)
        }
        this.$emit('update-status', data.custom_status)
      }
    },
    async tradeChange (val) {
      this.stradeList = (await this.tradeList.find(({ trade_id: id }) => id - val === 0) || {}).children || []

      this.$set(this.form, 'second_trade_id', '')
    },
    async getUserList (keyword) {
      const data = await this.$wPost('/operate/custom/search_user.do', {
        keyword
      })
      this.options = data
    },
    leaderToggle (res) {
      console.log(res)
      this.form.user_list = res
    },
    previewImg (file, index) {
      let url = ''
      if (file.response) {
        url = file.response.data
      } else {
        url = file.url
      }
      window.open(url)
    },
    handelUpload (filed, fileList) {
      this.form[filed] = fileList
    },
    async imgValid (file) {
      return await wImgValid(file, 0, 0)
    },
    uploadSuccess (response, file, fileList) {
      console.log(response)
      if (response.code === 200) {
        this.handelUpload('businessLicense', [fileList[fileList.length - 1]])
        this.$refs.form.validateField('businessLicense')
      }
    },
    uploadRemove (file, fileList) {
      this.handelUpload('businessLicense', fileList)
    },
    submit () {
      this.$refs.form.validate(v => {
        if (v) {
          if (this.isAdd) {
            this.addSubmit()
          } else {
            this.editSubmit()
          }
        } else {
          this.$refs.submitButton.updatedLoading(false)
        }
      })
    },
    async addSubmit () {
      const payload = new HandleData(this.form).operateAdd()
      const data = await this.$wPost('/operate/custom/add.do', payload)
      if (data === true) {
        this.$wToast.success('新建成功')
        this.$router.replace({ path: '/operate/custom' })
      }
      this.$refs.submitButton.updatedLoading(false)
    },
    async editSubmit () {
      const payload = new HandleData(this.form).operateEdit()
      const data = await this.$wPost('/operate/custom/update.do', payload)
      if (data === true) {
        this.$wToast.success('修改成功')
        this.$router.replace({ path: '/operate/custom' })
      }
      this.$refs.submitButton.updatedLoading(false)
    },
    cancel () {
      history.go(-1)
    }
  },
  computed: {
    ...mapGetters([
      'enum_map'
    ]),
    isAdd () {
      if (/add/.test(this.$route.path)) {
        return true
      } else {
        return false
      }
    }
  },
  created () {
    this.init()
  }
}
