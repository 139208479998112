/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-24 16:23:06
 * @LastEditors: AllenXD
 * @LastEditTime: 2022-05-27 14:16:58
 */
class HandleData {
  constructor (payload) {
    this.payload = payload
  }

  operateAdd () {
    const newPayload = {
      name: this.payload.name,
      product_name: this.payload.product_name,
      first_trade_id: this.payload.first_trade_id,
      second_trade_id: this.payload.second_trade_id,
      contact_person_name: this.payload.contact_person_name,
      phone: this.payload.phone,
      email: this.payload.email,
      license_path: this.payload.businessLicense.length ? this.payload.businessLicense[0].response.data : '',
      file_name: this.payload.businessLicense.length ? this.payload.businessLicense[0].name : '',
      audience_desc: this.payload.audience_desc
    }

    const userList = this.payload.user_list.map(v => {
      if (v.id) {
        return v.id
      }
    })
    newPayload.user_list = userList

    return newPayload
  }

  operateEdit () {
    const newPayload = {
      id: this.payload.custom_id,
      name: this.payload.name,
      product_name: this.payload.product_name,
      first_trade_id: this.payload.first_trade_id,
      second_trade_id: this.payload.second_trade_id,
      contact_person_name: this.payload.contact_person_name,
      phone: this.payload.phone,
      email: this.payload.email,
      audience_desc: this.payload.audience_desc
    }
    const userList = this.payload.user_list.map(v => {
      if (v.id) {
        return v.id
      }
    })
    if (this.payload.businessLicense.length && this.payload.businessLicense[0].response) {
      newPayload.license_path = this.payload.businessLicense[0].response.data
      newPayload.file_name = this.payload.businessLicense[0].name
    } else {
      newPayload.license_path = this.payload.businessLicense.length ? this.payload.businessLicense[0].url : ''
      newPayload.file_name = this.payload.businessLicense.length ? this.payload.businessLicense[0].name : ''
    }
    newPayload.user_list = userList

    return newPayload
  }

  adminResolve () {
    const newPayload = {
      id: this.payload.custom_id,
      custom_status: 3
    }
    return newPayload
  }

  adminReject () {
    const newPayload = {
      id: this.payload.id,
      reason: this.payload.reason,
      custom_status: 2
    }
    return newPayload
  }

  adminCombindUser () {
    const newPayload = {
      id: this.payload.custom_id
    }
    const userList = this.payload.user_list.map(v => {
      if (v.id) {
        return v.id
      }
    })
    newPayload.user_list = userList
    return newPayload
  }

  editAppCombo () {
    const newPayload = {
      id: this.payload.id,
      storage_cnt: this.payload.storage_cnt,
      expire_date: this.payload.expire_date,
      pay_money: Number(this.payload.pay_money) * 100
    }
    return newPayload
  }
}

export default HandleData
